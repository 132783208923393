import { ThunkDispatch, UnknownAction } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import dayjs from "dayjs";

import { getHeaders } from "#shared/components/api/utils";
import { Cookies } from "#shared/types";
import { BriefBuilderApi } from "#src/apis/briefBuilder/api";
import { QUERY_TAGS } from "#src/constants/query";
import env from "#src/env";
import { channelsApi } from "#src/features/channel/channelsAPI";
import { appBaseQuery } from "#src/features/common/baseQuery";
import { feedApi } from "#src/features/feed/feedAPI";
// import { AdminBriefStatus } from "#src/types/brief";
import { AdminBriefStatus } from "#src/types//brief";
import {
  RequestBriefBuilderLock,
  BriefBuilderStep,
  CreateEmptyBriefResponse,
  GetBriefBuilderStepsResponse,
  CreateEmptyBriefRequest,
  BriefBuilderData,
  SaveBriefTitleRequest,
  SaveBriefCoverRequest,
  SaveBriefCoverType,
  SaveBriefContentRequest,
  SaveBriefBaseResponse,
  SaveBriefCoverResponse,
  SaveBriefGeneralRequest,
  CalculateBriefBuilderStepsRequest,
  SaveBriefAudienceRequest,
  CalculateBriefAudienceResponse,
  SaveBriefLaunchRequest,
  SaveBriefLaunchResponse,
  DeleteBriefNotificationRequest,
  SaveBriefSurveyRequest,
  GetBriefBuilderLockResponse,
  BriefTargetingType,
} from "#src/types/briefBuilder";
import createApi from "../../../createApi";

export const briefBuilderApi = createApi({
  reducerPath: "briefBuilder",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/community/brief-builder/`,
  }),
  tagTypes: [
    QUERY_TAGS.BriefBuilder,
    QUERY_TAGS.BriefBuilderSteps,
    QUERY_TAGS.BriefBuilderLock,
  ],
  endpoints: (builder) => ({
    createEmptyBrief: builder.mutation<CreateEmptyBriefResponse, CreateEmptyBriefRequest>(
      {
        query: (body) => ({
          url: `draft/create`,
          method: "POST",
          body,
          credentials: "include",
        }),
        onQueryStarted: async ({ channelRef }, { dispatch, queryFulfilled }) => {
          await queryFulfilled;

          dispatch(channelsApi.util.invalidateTags([QUERY_TAGS.ChannelAdminBriefs]));
          dispatch(
            channelsApi.util.invalidateTags([
              { type: QUERY_TAGS.Channel, id: channelRef },
            ])
          );
        },
      }
    ),

    fetchBuilderBrief: builder.query<
      BriefBuilderData,
      { briefRef: string; cookies?: Cookies }
    >({
      query: ({ briefRef, cookies }) => ({
        url: `draft/brief`,
        method: "GET",
        params: { briefRef },
        headers: getHeaders(cookies),
        credentials: "include",
      }),
      serializeQueryArgs: ({ endpointName, queryArgs: { briefRef } }) =>
        JSON.stringify({ endpointName, briefRef }),
      providesTags: (_result, _error, { briefRef }) => [
        { type: QUERY_TAGS.BriefBuilder, id: briefRef },
      ],
      keepUnusedDataFor: 0,
    }),

    /* Builder steps */
    fetchBuilderSteps: builder.query<GetBriefBuilderStepsResponse, string>({
      query: (briefRef) => ({
        url: `draft/get-steps`,
        method: "GET",
        params: { briefRef },
        credentials: "include",
      }),
      providesTags: (_result, _error, briefRef) => [
        { type: QUERY_TAGS.BriefBuilderSteps, id: briefRef },
      ],
    }),
    calculateBuilderSteps: builder.mutation<
      GetBriefBuilderStepsResponse,
      CalculateBriefBuilderStepsRequest
    >({
      query: ({ launched, ...params }) => ({
        url: `draft/calculate-steps`,
        method: "GET",
        params,
        credentials: "include",
      }),
      onQueryStarted: async (
        { briefRef, activeStep, launched },
        { dispatch, queryFulfilled }
      ) => {
        const { data } = await queryFulfilled;

        if (data.steps) {
          dispatch(updateBuilderSteps(briefRef, data.steps));
          if (!launched) {
            dispatch(updateBriefCache(briefRef, { currentBuildStep: activeStep }));
          }
        }
      },
    }),

    /* Builder lock */
    fetchBuilderLock: builder.query<GetBriefBuilderLockResponse, string>({
      query: (briefRef) => ({
        url: `lock`,
        method: "GET",
        params: { briefRef },
        credentials: "include",
      }),
      providesTags: (_result, _error, briefRef) => [
        { type: QUERY_TAGS.BriefBuilderLock, id: briefRef },
      ],
      keepUnusedDataFor: 0,
    }),
    requestBuilderLock: builder.query<void, RequestBriefBuilderLock>({
      query: (body) => ({
        url: `request-lock`,
        method: "POST",
        body,
        credentials: "include",
      }),
      keepUnusedDataFor: 0,
    }),
    releaseBuilderLock: builder.mutation<void, RequestBriefBuilderLock>({
      query: (body) => ({
        url: `release-lock`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),

    saveBriefCover: builder.mutation<SaveBriefCoverResponse, SaveBriefCoverRequest>({
      queryFn: async (
        { briefRef, coverPhoto, coverVideo, wallpaperId, unsplashImageId },
        _queryApi,
        _extraOptions,
        fetchWithBQ
      ) => {
        // cover wallpaper
        if (wallpaperId) {
          const response = await fetchWithBQ({
            url: `draft/save-cover`,
            method: "POST",
            body: { briefRef, type: SaveBriefCoverType.Wallpaper, wallpaperId },
            credentials: "include",
          });

          if (response.error) {
            return { error: response.error as FetchBaseQueryError };
          }

          return { data: response.data as SaveBriefCoverResponse };
        }

        if (unsplashImageId) {
          const response = await fetchWithBQ({
            url: `draft/save-cover`,
            method: "POST",
            body: { briefRef, type: SaveBriefCoverType.UnsplashImage, unsplashImageId },
            credentials: "include",
          });

          if (response.error) {
            return { error: response.error as FetchBaseQueryError };
          }

          return { data: response.data as SaveBriefCoverResponse };
        }

        // cover video and thumbnail
        if (coverVideo && coverPhoto) {
          await BriefBuilderApi.uploadCoverVideo(briefRef, coverVideo, coverPhoto);

          const response = await fetchWithBQ({
            url: `draft/save-cover`,
            method: "POST",
            body: { briefRef, type: SaveBriefCoverType.Video, wallpaperId: "" },
            credentials: "include",
          });

          if (response.error) {
            return { error: response.error as FetchBaseQueryError };
          }

          return {
            data: response.data as SaveBriefCoverResponse,
          };
        }

        // cover photo
        if (!coverPhoto) {
          throw new Error("No cover photo provided");
        }

        await BriefBuilderApi.uploadCoverPhoto(briefRef, coverPhoto);

        const response = await fetchWithBQ({
          url: `draft/save-cover`,
          method: "POST",
          body: { briefRef, type: SaveBriefCoverType.Photo, wallpaperId: "" },
          credentials: "include",
        });

        if (response.error) {
          return { error: response.error as FetchBaseQueryError };
        }

        return {
          data: response.data as SaveBriefCoverResponse,
        };
      },
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateBriefCache(req.briefRef, {
            coverPhoto: data.coverPhoto,
            coverVideoWeb: data.coverVideoWeb,
            coverWallpaperId: data.coverWallpaperId,
          })
        );
        invalidateBriefsCache(req.briefRef, dispatch);
      },
    }),
    saveBriefTitle: builder.mutation<SaveBriefBaseResponse, SaveBriefTitleRequest>({
      query: ({ briefRef, title }) => ({
        url: `draft/save-brief`,
        method: "POST",
        body: { briefRef, title },
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateSaveCache(data, {
            title: req.title,
          })
        );
        dispatch(updateBuilderSteps(req.briefRef, data.steps));
        invalidateBriefsCache(req.briefRef, dispatch);
      },
    }),
    saveBriefContent: builder.mutation<SaveBriefBaseResponse, SaveBriefContentRequest>({
      query: (body) => ({
        url: `draft/save-brief-editor`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateSaveCache(data, {
            contentDocumentJson: req.contentDocumentJson,
          })
        );
        dispatch(updateBuilderSteps(req.briefRef, data.steps));
        invalidateBriefsCache(req.briefRef, dispatch);
      },
    }),
    saveBriefGeneral: builder.mutation<SaveBriefBaseResponse, SaveBriefGeneralRequest>({
      query: (body) => ({
        url: `draft/save-general`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateSaveCache(data, {
            channelId: req.channelId,
            internalName: req.internalName,
            projectId: req.projectId,
            responseType: req.responseType,
            targetingType: req.targetingType,
          })
        );
        // Invalidate audience if targeting is audience
        if (req.targetingType === BriefTargetingType.Segment) {
          dispatch(
            briefBuilderApi.util.invalidateTags([
              { type: QUERY_TAGS.BriefBuilder, id: req.briefRef },
            ])
          );
        }
        dispatch(updateBuilderSteps(req.briefRef, data.steps));
        invalidateBriefsCache(req.briefRef, dispatch);
      },
    }),
    saveBriefAudience: builder.mutation<SaveBriefBaseResponse, SaveBriefAudienceRequest>({
      query: (body) => ({
        url: `draft/save-audience`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateSaveCache(data, {
            audience: req,
          })
        );
      },
    }),
    saveBriefSurvey: builder.mutation<SaveBriefBaseResponse, SaveBriefSurveyRequest>({
      query: (body) => ({
        url: `draft/save-survey`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (_req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(updateSaveCache(data, {}));
      },
    }),
    saveBriefLaunch: builder.mutation<SaveBriefLaunchResponse, SaveBriefLaunchRequest>({
      query: (body) => ({
        url: `draft/save-launch`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        const { data } = await queryFulfilled;

        dispatch(
          updateSaveCache(data, {
            status: dayjs(req.startsOn).isAfter(dayjs())
              ? AdminBriefStatus.Scheduled
              : AdminBriefStatus.Active,
            startsOn: req.startsOn,
            endsOn: req.endsOn || "",
            launchNotification: data.launchNotification,
            notifications: data.notifications,
          })
        );
      },
    }),
    deleteBriefNotification: builder.mutation<void, DeleteBriefNotificationRequest>({
      query: (body) => ({
        url: `draft/delete-notification`,
        method: "POST",
        body,
        credentials: "include",
      }),
      onQueryStarted: async (req, { dispatch, queryFulfilled }) => {
        await queryFulfilled;

        dispatch(removeNotificationBriefCache(req.briefRef, req.notificationId));
      },
    }),
    calculateBriefAudience: builder.query<
      CalculateBriefAudienceResponse,
      SaveBriefAudienceRequest
    >({
      query: (body) => ({
        url: `draft/calculate-audience`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),
    requestRecruitment: builder.mutation<void, SaveBriefAudienceRequest>({
      query: (body) => ({
        url: `draft/request-recruitment`,
        method: "POST",
        body,
        credentials: "include",
      }),
    }),

    // save/edit brief launch
  }),
});

// TODO: every edit endpoint returns steps, so we can update the steps query with the new data

const invalidateBriefsCache = (
  briefRef: string,
  dispatch: ThunkDispatch<any, any, UnknownAction>
) => {
  dispatch(channelsApi.util.invalidateTags([QUERY_TAGS.ChannelAdminBriefs]));
  dispatch(
    feedApi.util.invalidateTags([
      { type: QUERY_TAGS.Brief, id: briefRef },
      { type: QUERY_TAGS.Briefs, id: briefRef },
    ])
  );
};

const updateSaveCache = (
  response: SaveBriefBaseResponse,
  additionalData: Partial<BriefBuilderData>
) => {
  return updateBriefCache(response.briefRef, {
    steps: response.steps,
    currentBuildStep: response.currentBuildStep,
    ...additionalData,
  });
};

const updateBriefCache = (briefRef: string, data: Partial<BriefBuilderData>) => {
  return briefBuilderApi.util.updateQueryData(
    "fetchBuilderBrief",
    { briefRef },
    (oldData) => ({
      ...oldData,
      ...data,
    })
  );
};

const removeNotificationBriefCache = (briefRef: string, notificationId: number) => {
  return briefBuilderApi.util.updateQueryData(
    "fetchBuilderBrief",
    { briefRef },
    (oldData) => {
      return {
        ...oldData,
        notifications: oldData.notifications?.filter((n) => n.id !== notificationId),
      };
    }
  );
};

const updateBuilderSteps = (briefRef: string, steps: BriefBuilderStep[]) => {
  return briefBuilderApi.util.updateQueryData("fetchBuilderSteps", briefRef, () => ({
    steps,
  }));
};

export const {
  useFetchBuilderBriefQuery,
  useCreateEmptyBriefMutation,
  useFetchBuilderStepsQuery,
  useCalculateBuilderStepsMutation,
  useFetchBuilderLockQuery,
  useSaveBriefTitleMutation,
  useSaveBriefCoverMutation,
  useSaveBriefContentMutation,
  useSaveBriefGeneralMutation,
  useSaveBriefSurveyMutation,
  useSaveBriefAudienceMutation,
  useSaveBriefLaunchMutation,
  useDeleteBriefNotificationMutation,
  useCalculateBriefAudienceQuery,
  useRequestRecruitmentMutation,
  useRequestBuilderLockQuery,
  useReleaseBuilderLockMutation,
} = briefBuilderApi;
