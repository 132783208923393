import { omit } from "radash";

import { FetchPollItemsByBriefRefParams } from "#src/apis/fetchPollItemsByBriefRef";
import { GetBriefCardInfoRequest, GetBriefDetailsRequest } from "#src/types/brief";
import { QueryKeyParamsFromRequest } from "#src/types/reactQuery";
import { FetchFeedProps } from "#src/types/types";

export enum QueryKeyEntity {
  brief = "brief",
  channels = "channels",
  chat = "chat",
  pollItems = "pollItems",
  briefBuilder = "brief-builder",
  superAdmin = "super-admin",
  unsplash = "unsplash",
  interests = "interests",
  organisations = "organisations",
  wrapped = "wrapped",
  projects = "projects",
}

export enum QueryKeyType {
  list = "list",
  details = "details",
  channel = "channel",
  token = "token",
  briefMetrics = "briefMetrics",
  interests = "interests",
  personality = "personality",
  badges = "badges",
  userInsights = "userInsights",
  alignment = "alignment",
  matchingConnections = "matchingConnections",
  shareToken = "shareToken",
}

export const BriefQueryKeys = {
  list: (params?: QueryKeyParamsFromRequest<FetchFeedProps>) =>
    createQueryKey([QueryKeyEntity.brief, QueryKeyType.list], params),
  details: (params?: QueryKeyParamsFromRequest<GetBriefDetailsRequest>) =>
    createQueryKey([QueryKeyEntity.brief, QueryKeyType.details], params),
  cardInfo: (params?: QueryKeyParamsFromRequest<GetBriefCardInfoRequest>) =>
    createQueryKey([QueryKeyEntity.brief, "card-info"], params),
};

export const ChatQueryKeys = {
  channel: [QueryKeyEntity.chat, QueryKeyType.channel],
  token: [QueryKeyEntity.chat, QueryKeyType.token],
};

export const PollItemsQueryKeys = {
  listByBriefRef: (params?: QueryKeyParamsFromRequest<FetchPollItemsByBriefRefParams>) =>
    createQueryKey(
      [QueryKeyEntity.pollItems, QueryKeyEntity.brief, QueryKeyType.list],
      params
    ),
};

export const WrappedQueryKeys = {
  briefMetrics: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.briefMetrics]),
  interests: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.interests]),
  personality: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.personality]),
  badges: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.badges]),
  userInsights: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.userInsights]),
  alignment: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.alignment]),
  matchingConnections: () =>
    createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.matchingConnections]),
  shareToken: () => createQueryKey([QueryKeyEntity.wrapped, QueryKeyType.shareToken]),
};

export const createQueryKey = (baseKeys: string[], params?: any) => [
  ...baseKeys,
  ...(params ? [omit(params, ["page", "pageSize", "start", "cookies"])] : []),
];
