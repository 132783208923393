import env from "#src/env";
import { appBaseQuery } from "#src/features/common/baseQuery";
import createApi from "../../../createApi";
import { RootState } from "../../store";
import { Country, Language } from "../../types/db";

export const commonApi = createApi({
  reducerPath: "commonSlice",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_API_BASE_URL}/`,
  }),
  tagTypes: ["countries"],
  endpoints: (builder) => ({
    fetchCountries: builder.query<Country[], void>({
      query: () => {
        return {
          url: "countries",
        };
      },
      transformResponse: (countries: Country[]) => countries,
      serializeQueryArgs: () => "countries",
    }),
    fetchLanguages: builder.query<{ data: Language[] }, void>({
      query: () => ({
        url: "community/languages/list",
        method: "GET",
        credentials: "include",
      }),
    }),
  }),
});

export const { useFetchCountriesQuery, useFetchLanguagesQuery } = commonApi;
export type UseFetchCountriesQuery = typeof useFetchCountriesQuery;
export const selectCountries = (state: RootState) => ({ commonSlice: state.commonSlice });
