import { AxiosHeaders } from "axios";

import { getHeaders } from "#shared/components/api/utils";
import { TransformUrlParam } from "#shared/utils/transform.utils";
import { client } from "#src/apis/axios";
import { transformBriefDetailsResponse } from "#src/apis/briefs/transform";
import { WLA_HEADER } from "#src/constants/common";
import {
  BriefCardInfo,
  DuplicateBriefRequest,
  DuplicateBriefResponse,
  GetBriefCardInfoRequest,
  GetBriefDetailsRequest,
  GetBriefDetailsResponse,
} from "#src/types/brief";
import { Brief } from "#src/types/db";

export namespace BriefsApi {
  const BASE_PREFIX = "/briefs";

  export const fetchBriefDetails = async (
    params: GetBriefDetailsRequest
  ): Promise<Brief> => {
    const { cookies, briefRef } = params;

    const { data } = await client.get<GetBriefDetailsResponse>(
      `${BASE_PREFIX}/${TransformUrlParam.brief(briefRef)}`,
      {
        params: {
          includeContent: "html",
        },
        headers: {
          ...(getHeaders(cookies) as AxiosHeaders),
          ...(params.organisationId && {
            [WLA_HEADER]: params.organisationId,
          }),
        },
      }
    );

    return transformBriefDetailsResponse(data);
  };

  export const fetchCardInfo = async ({ briefRef }: GetBriefCardInfoRequest) => {
    const { data } = await client.get<BriefCardInfo>(
      `/community/briefs/${briefRef}/card-info`
    );
    return data;
  };

  export const duplicateBrief = async ({ briefRef, ...body }: DuplicateBriefRequest) => {
    const { data } = await client.post<DuplicateBriefResponse>(
      `${BASE_PREFIX}/${briefRef}/duplicate`,
      { ...body }
    );

    return data;
  };
}
