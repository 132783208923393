import { AxiosError } from "axios";

import { QueryParams, objectToGetParams } from "#shared/utils/url.utils";
import { ApiError, ApiErrorResponse } from "#src/types/api";

export function getErrorMessage(error: unknown): string | undefined {
  if (typeof error === "object" && error != null && "message" in error) {
    return (error as any).message;
  }
  return undefined;
}

/**
 * Extracts the error message from an Axios error.
 * @param error The error to extract the message from.
 * @returns The error message.
 */
export function getAxiosErrorMessage(error: unknown): string | undefined {
  const axiosError = error as AxiosError;

  if (axiosError.isAxiosError) {
    return (
      getErrorMessage(axiosError.response?.data)?.toLowerCase() || axiosError.message
    );
  }

  if ("data" in axiosError) {
    return getErrorMessage(axiosError.data)?.toLowerCase() || axiosError.message;
  }

  return getErrorMessage(error);
}

/**
 * Extracts the common api error object from an Axios error.
 * @param error The error to extract the api error from.
 * @returns The api error object.
 */
export function mapApiError<TName, TData>(
  error: AxiosError<ApiError<TName, TData>>
): ApiErrorResponse<TName, TData> {
  const axiosError = error as AxiosError;

  if (!axiosError.isAxiosError) {
    return {
      message: (getErrorMessage(error) || "") as any,
    };
  }

  const response = (axiosError.response?.data as any)?.response as ApiErrorResponse<
    TName,
    TData
  >;

  if (!response) {
    return {
      message: (getErrorMessage(error) || "") as any,
    };
  }

  return {
    message: response.message,
    data: response?.data,
  };
}

export function appendParamsToUrl(url: string, params: QueryParams) {
  return `${url}${url.includes("?") ? "&" : "?"}${objectToGetParams(params).replace(
    /^\?/,
    ""
  )}`;
}
