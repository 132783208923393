import { GetBriefDetailsResponse } from "#src/types/brief";
import { Brief } from "#src/types/db";

export const transformBriefDetailsResponse = (
  response: GetBriefDetailsResponse
): Brief => {
  const transformedData: Brief = {
    ...response.data,
    canInteract: !!response.isUserTargetedByBrief,
  };

  return transformedData;
};
