// we don't want to include all the envs in the client, so we filter with PUBLIC_ENVS
// @ts-ignore
import { isBrowser } from "../utils";

const PUBLIC_ENVS: (keyof Environment)[] = [
  "VITE_BS_API_BASE_URL",
  "VITE_ONE_LINK_URL",
  "VITE_VUE_APP_BS_API_BASE_URL",
  "VITE_VUE_APP_BS_API_APP_ID",
  "VITE_VUE_APP_BASE_URL",
  "VITE_BASE_URL_UI",
  "VITE_APP_NAME",
  "VITE_GOOGLE_ANALYTICS_TRACKING_ID",
  "VITE_API_TEST_URL",
  "VITE_GOOGLE_TAGS_ID",
  "VITE_FINGERPRINT_API_KEY",
  "VITE_API_BASE_URL",
  "VITE_DISABLE_JWT_CHECK",
  "VITE_STREAMIO_ID",
  "VITE_RESOURCES_BASE_URL",
  "VITE_QR_URL_BASE",
  "VITE_TIPTAP_AI_APP_ID",
  "VITE_TIPTAP_AI_BASE_URL",
  "SENTRY_DSN",
];

type Environment = {
  [key: string]: any;
  VITE_BS_API_BASE_URL: string;
  VITE_ONE_LINK_URL: string;
  VITE_VUE_APP_BASE_URL: string;
  VITE_VUE_APP_BS_API_BASE_URL: string;
  VITE_VUE_APP_BS_API_APP_ID: string;
  VITE_BASE_URL_UI: string;
  VITE_APP_NAME: string;
  VITE_GOOGLE_ANALYTICS_TRACKING_ID: string;
  VITE_GOOGLE_TAGS_ID: string;
  FINGERPRINT_API_KEY: string;
  VITE_API_BASE_URL: string;
  VITE_DISABLE_JWT_CHECK: string;
  VITE_STREAMIO_ID: string;
  VITE_RESOURCES_BASE_URL: string;
  VITE_QR_URL_BASE: string;
  VITE_TIPTAP_AI_APP_ID: string;
  VITE_TIPTAP_AI_BASE_URL: string;
  SENTRY_DSN: string | undefined;
};

export const setEnvs = (envVariables: Record<string, any>) => {
  const envs: Record<string, any> = {};
  // to access server side
  for (const v of PUBLIC_ENVS) {
    // testing purpose
    envs[v] = envVariables[v];
  }

  return envs;
};

// window.__ENV__ is defined by setEnvs in /renderer/_default.page.server.tsx
// @ts-ignore
export default isBrowser ? window.env : (process.env as Environment) || {};
