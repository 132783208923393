import { v4 as uuidv4 } from "uuid";

import { getHeaders } from "#shared/components/api/utils";
import env from "#src/env";
import { appBaseQuery } from "#src/features/common/baseQuery";
import createApi from "../../../createApi";
import { Cookies } from "../../types/types";

export interface OneTimeUseTokenParams {
  cookies?: Cookies;
  token?: string;
}

interface OneTimeUseTokenDTO {
  data: { token?: string };
}

export const oneTimeUseTokenApi = createApi({
  reducerPath: "oneTimeUseToken",
  baseQuery: appBaseQuery({
    baseUrl: `${env.VITE_VUE_APP_BS_API_BASE_URL}/`,
  }),
  endpoints: (builder) => ({
    fetchOneTimeUseToken: builder.query<string | undefined, OneTimeUseTokenParams>({
      query: (args) => {
        return {
          url: `request_otat`,
          headers: getHeaders(args.cookies),
          credentials: "include",
          method: "POST",
          body: JSON.stringify({
            args: {
              token: args.token,
              device_type: 3,
              device_unique_id: uuidv4(),
              device_name: navigator?.userAgent,
              device_os: navigator?.platform,
            },
          }),
        };
      },
      keepUnusedDataFor: 0,
      transformResponse: (response: OneTimeUseTokenDTO) => {
        if (!response) {
          throw new Error("No token received");
        }
        return response.data.token;
      },
    }),
  }),
});

export const { useLazyFetchOneTimeUseTokenQuery } = oneTimeUseTokenApi;
