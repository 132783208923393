import { client } from "#src/apis/axios";
import generatePresignedUrl, {
  PresignedUrlType,
} from "#src/apis/upload/generatePresignedUrl";
import uploadPresignedFile from "#src/apis/upload/uploadPresignedFile";
import {
  GetBriefWallpapersResponse,
  GenerateBriefImageRequest,
  GenerateBriefImageResponse,
  GenerateBriefRequest,
  GenerateBriefResponse,
  GetTiptapTokenResponse,
} from "#src/types/briefBuilder";

export namespace BriefBuilderApi {
  const BASE_PREFIX = "/community/brief-builder";

  /* Brief generation methods */

  export const generateBrief = async (
    body: GenerateBriefRequest
  ): Promise<GenerateBriefResponse> => {
    const { data } = await client.post<GenerateBriefResponse>(
      `${BASE_PREFIX}/chat/request-generation`,
      body
    );
    return data;
  };

  export const getGenerationStatus = async (briefGenerateRequestId: string) => {
    const { data } = await client.get<GenerateBriefResponse>(
      `/brief-generate-request/${briefGenerateRequestId}`
    );
    return data;
  };

  export const generateImage = async (
    body: GenerateBriefImageRequest
  ): Promise<GenerateBriefImageResponse> => {
    const { data } = await client.post<GenerateBriefImageResponse>(
      `${BASE_PREFIX}/image/generate`,
      body,
      {
        timeout: 2 * 60 * 1000,
      }
    );
    return data;
  };

  export const getWallpapers = async () => {
    const { data } = await client.get<GetBriefWallpapersResponse>(
      "/community/wallpapers"
    );
    return data;
  };

  export const getTiptapToken = async () => {
    const { data } = await client.post<GetTiptapTokenResponse>("/tiptap/token");
    return data;
  };

  /* Upload media methods */

  export const uploadCoverVideo = async (
    briefRef: string,
    coverVideo: File,
    coverThumbnail: File
  ) => {
    const presignedVideo = await generatePresignedUrl({
      type: PresignedUrlType.BriefCoverVideo,
      contentType: "video/mp4",
      body: { briefRef },
    });

    const presignedThumbnail = await generatePresignedUrl({
      type: PresignedUrlType.BriefCoverPhoto,
      body: { briefRef },
    });

    if (!presignedVideo || !presignedThumbnail) {
      throw new Error("No presigned URL received");
    }

    await uploadPresignedFile({
      data: presignedVideo,
      contentType: "video/mp4",
      file: coverVideo,
    });
    await uploadPresignedFile({ data: presignedThumbnail, file: coverThumbnail });
  };

  export const uploadCoverPhoto = async (briefRef: string, coverPhoto: File) => {
    const presignedPhoto = await generatePresignedUrl({
      type: PresignedUrlType.BriefCoverPhoto,
      body: { briefRef },
    });

    if (!presignedPhoto) {
      throw new Error("No presigned URL received");
    }

    await uploadPresignedFile({ data: presignedPhoto, file: coverPhoto });
  };
}
