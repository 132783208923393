import { TypedUseSelectorHook, useSelector } from "react-redux";
import { Location } from "react-router-dom";

import { RGBA } from "#src/types/types";
import { PROTECTED_PAGES_REGEX } from "../constants/common";
import { RootState } from "../store";

const useSelectorTyped: TypedUseSelectorHook<RootState> = useSelector;

function getQueryParams(location: Location, param: string): string | null {
  const searchParams = new URLSearchParams(location.search);
  return searchParams.get(param) || searchParams.get(param.toUpperCase());
}

function validateEmail(email = "") {
  return /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(email);
}

function validateWebsite(website = "") {
  const pattern = new RegExp(
    "^([a-zA-Z]+:\\/\\/)?" + // protocol
      "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
      "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR IP (v4) address
      "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
      "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
      "(\\#[-a-z\\d_]*)?$", // fragment locator
    "i"
  );
  return pattern.test(website);
}

function rgba2hex({ R, G, B, A }: RGBA): string {
  let hex = `#${((1 << 24) + (R << 16) + (G << 8) + B).toString(16).slice(1)}`;

  // added alpha param if exists
  if (A) {
    const a = ((A * 255) | (1 << 8)).toString(16).slice(1);
    hex = hex + a;
  }
  return hex;
}

function hexToRgb(hex: string) {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        R: parseInt(result[1], 16),
        G: parseInt(result[2], 16),
        B: parseInt(result[3], 16),
      }
    : null;
}

const isBrowser = typeof window != "undefined";

const isProtectedPage = (pathname: string | undefined): boolean =>
  pathname === "/" || (pathname !== undefined && PROTECTED_PAGES_REGEX.test(pathname));

const nFormatter = (num: number): string | number => {
  if (num >= 1000000000) {
    return (num / 1000000000).toFixed(1).replace(/\.0$/, "") + "G";
  }
  if (num >= 1000000) {
    return (num / 1000000).toFixed(1).replace(/\.0$/, "") + "M";
  }
  if (num >= 1000) {
    return (num / 1000).toFixed(1).replace(/\.0$/, "") + "K";
  }
  return num;
};

function getRoutePathname() {
  const url = new URL(window.location.href);
  return url.pathname;
}

export {
  useSelectorTyped,
  nFormatter,
  getQueryParams,
  isProtectedPage,
  validateEmail,
  validateWebsite,
  rgba2hex,
  hexToRgb,
  isBrowser,
  getRoutePathname,
};
